"use client";

import Link from 'next/link';
import Image from 'next/image';
import { Suspense, useState } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { usePathname } from 'next/navigation';

export const experimental_ppr = true;

interface NavLinkProps {
  href: string;
  children: React.ReactNode;
  textColor: string;
  isActive: boolean;
  onClick?: () => void;
}

const NavLink: React.FC<NavLinkProps> = ({ href, children, textColor, isActive, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link 
      href={href} 
      aria-label={children as string}
      className={`px-4 py-2 text-sm font-bold uppercase text-nowrap transition duration-300 md:rounded-full ${
        isActive
          ? 'text-green-600'
          : isHovered
          ? 'hover:text-green-600'
          : `hover:text-green-600 ${textColor}`
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

export default function Navbar({ color, textColor }: { color: string, textColor: string }) {
  const [isOpen, setIsOpen] = useState(false);
  const pathname = usePathname();

  const closeMenu = () => setIsOpen(false);

  return (
    <nav className={`${color} sticky top-0 z-50 border-b border-grey-500`}> 
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="flex flex-col items-center justify-center h-auto py-4">
          <div className="flex-shrink-0">
            <Suspense fallback={<div>Loading...</div>}>
              <Link href="/">
                <Image
                  src="/images/logo.png"
                  alt="Your Logo"
                  width={240}
                  height={80}
                />
              </Link>
            </Suspense>
          </div>
          <div className="hidden md:block relative w-full">
            <div className="flex items-center justify-center">
              <div className="top-1/2 w-1/5 lg:w-1/4 h-1 mx-8 bg-gradient-to-r from-blue-500 to-green-500 transform -translate-y-1/2"></div>
              <NavLink href="/" textColor={textColor} isActive={pathname === '/'}>Home</NavLink>
              <NavLink href="/about" textColor={textColor} isActive={pathname === '/about'}>About</NavLink>
              <NavLink href="/services" textColor={textColor} isActive={pathname === '/services'}>Services</NavLink>
              <NavLink href="/work" textColor={textColor} isActive={pathname === '/work'}>Our Work</NavLink>
              <NavLink href="/gallery" textColor={textColor} isActive={pathname === '/gallery'}>Gallery</NavLink>
              <NavLink href="/contact" textColor={textColor} isActive={pathname === '/contact'}>Contact</NavLink>
              <div className="top-1/2 w-1/5 lg:w-1/4 h-1 mx-8 bg-gradient-to-l from-blue-500 to-green-500 transform -translate-y-1/2"></div>
            </div>
          </div>
          <div className="md:hidden absolute right-4">
            <button
              aria-label="Open main menu"
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className={`${color} inline-flex items-center justify-center p-2 rounded-none bg-green-950 focus:outline-none focus:ring-2 focus:ring-inset`}
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? (
                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden fixed top-[104px] left-0 w-64 h-full bg-white shadow-lg z-40 transition-transform duration-300 ease-in-out transform" style={{ transform: isOpen ? 'translateX(0)' : 'translateX(-100%)' }}>
          <div className="flex flex-col py-4">
            <NavLink href="/" textColor={textColor} isActive={pathname === '/'} onClick={closeMenu}>Home</NavLink>
            <NavLink href="/about" textColor={textColor} isActive={pathname === '/about'} onClick={closeMenu}>About</NavLink>
            <NavLink href="/services" textColor={textColor} isActive={pathname === '/services'} onClick={closeMenu}>Services</NavLink>
            <NavLink href="/work" textColor={textColor} isActive={pathname === '/work'} onClick={closeMenu}>Our Work</NavLink>
            <NavLink href="/gallery" textColor={textColor} isActive={pathname === '/gallery'} onClick={closeMenu}>Gallery</NavLink>
            <NavLink href="/contact" textColor={textColor} isActive={pathname === '/contact'} onClick={closeMenu}>Contact</NavLink>
          </div>
        </div>
      )}
    </nav>
  );
};
