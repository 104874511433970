"use client"

import React, { useRef, useEffect } from 'react'

export default function VideoBackground() {

  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.source === iframeRef.current?.contentWindow) {
        if (event.data.event === 'onStateChange' && event.data.info === 2) {
          // Video paused, restart it
          iframeRef.current?.contentWindow?.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
        }
      }
    }

    window.addEventListener('message', handleMessage, { passive: true })
    return () => window.removeEventListener('message', handleMessage)
  }, [])

  return (
    <div className="relative w-full aspect-video overflow-hidden">
      <iframe
        ref={iframeRef}
        title="Background video"
        src="https://www.youtube-nocookie.com/embed/laL4XLV1VmI?autoplay=1&controls=0&disablekb=1&fs=0&iv_load_policy=3&loop=1&modestbranding=1&playsinline=1&rel=0&showinfo=0&mute=1&playlist=laL4XLV1VmI&enablejsapi=1"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        referrerPolicy="strict-origin-when-cross-origin"
        className="absolute top-0 left-0 w-full h-full border-0 pointer-events-none"
      />
    </div>
  )
}